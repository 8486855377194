import { Link } from "gatsby"
import React from "react"
import "./NewsCard.scss"
import { format } from "date-fns"
import { newsPageURL } from "../../site/urls"
import { ImageModule } from "../common/Image_Module"
import NoImg from "../../images/no-image.png";

const NewsCard = ({ news }) => {
  if (!news) return null

  const postedDate = format(new Date(news.post_on), "do MMM yyyy")

  return (
    <div key={news.id} className="news-card-wrap">
      <Link to={newsPageURL + news.slug + "/"} className="img-zoom">
        {news.tile_image.url ? (
          <ImageModule
            imagename={"blog.tile_image.tileimg"}
            ImageSrc={news.tile_image}
            ggfx_results={news.ggfx_results}
            altText={news.title}
            strapi_id={news.id}
            className="news-img"
          />
        ) : (
          <img src={NoImg} alt={"news - img"} className="news-img" />
        )}
      </Link>
      <div className="news-content">
        <Link to={newsPageURL + news.slug + "/"} className="news-title">
          {news.title}
        </Link>
        <div className="news-details">
          <i className="icon icon-calendar"></i>
          <p className="news-date">{postedDate}</p>
          {news.blog_category && (
            <>
              <div className="slant-divider"></div>
              <p className="news-category">{news.blog_category?.name}</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default NewsCard
